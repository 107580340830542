<template>
    <div
        class="skeleton w-full h-full"
    />
</template>

<script setup lang="ts">

</script>
<style lang="postcss" scoped>
@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}

.skeleton {
  margin: 1px; /* So two adjacent skeletons don't blur into each other */
  animation: pulse-bg 1s infinite;
}
</style>
