import EventEmitter from 'events';
import { ToggleId } from 'common';
import { print } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { PatchedRequestInit } from 'graphql-request/dist/types';
// eslint-disable-next-line
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import _ from 'lodash';
import { useFeatureToggles } from '@/composables/featureToggles/useFeatureToggles';
import authClientSingleton, { AuthClient } from '@api-client/auth';
import { DynamicQuery } from '@api-client/helpers/types';
import { env } from '../envVars';
import { isNormalVersionTag } from './helpers';

const { getFeatureToggles } = useFeatureToggles();

/**
 * Provides a request that can be used to send graphQL queries to a specified server/endpoint.
 * Not currently used.
 * This is a reference of if we want a graph-ql client, not running on the DES Data server.
 */

export class DesGraphQLClient extends EventEmitter {
    host: string;
    gqlClient: GraphQLClient;
    Auth: AuthClient;
    hasDetectedMismatch = false;

    constructor({ host, Auth, cors }: {
        host: string;
        Auth: AuthClient;
        cors?: boolean;
    }) {
        super();
        const options: PatchedRequestInit = {};
        if (cors) {
            options.mode = 'cors';
        }
        this.Auth = Auth;
        this.host = host;
        this.gqlClient = new GraphQLClient(`${host}/api/graphql`, options);
    }

    async request<ReturnType = any, Variables extends Record<string, any> = Record<string, any>>(
        query: TypedDocumentNode<ReturnType, Variables> | DynamicQuery<Variables, ReturnType>,
        variables?: Variables
    ): Promise<ReturnType> {
        try {
            const auth = await this.Auth.currentUser.then(u => u.freshAuth);
            const headers = {
                ...auth?.headersAccess,
                // TODO: Put the real version back
                ...(env.VERSION ? { 'X-Version': env.VERSION } : {}),
                'X-Feature-Toggles': JSON.stringify(getFeatureToggles())
            };
            if ('builder' in query) {
                const {
                    query: builtQuery,
                    queryVariables
                } = query.builder(variables);
                return this.rawRequest<ReturnType>(builtQuery, queryVariables, headers);
            }
            return this.rawRequest<ReturnType>(print(query), variables, headers);
        } catch (e: any) {
            if (e.response && e.response.errors && e.response.errors[0]) {
                throw new Error(`Error from GraphQL Server: ${e.response.errors[0].message}`);
            }
            throw e;
        }
    }

    async rawRequest<ReturnType, Variables extends Record<string, any> = Record<string, any>>(
        query: string,
        variables?: Variables,
        headers?: HeadersInit
    ): Promise<ReturnType> {
        const response = await this.gqlClient.rawRequest(query, variables, headers);
        const responseVersion = response.headers.get('X-Version');
        const requestVersion = headers?.['X-Version'];

        if (
            isNormalVersionTag(requestVersion)
            && isNormalVersionTag(responseVersion)
            && requestVersion !== responseVersion) {
            if (!this.hasDetectedMismatch) {
                console.warn('!!! Version mismatch !!!');
                console.warn(`  Client: ${requestVersion}`);
                console.warn(`  Server: ${responseVersion}`);
                this.emit('versionMismatch');
                this.hasDetectedMismatch = true;
            }
        }

        if (response.errors) {
            throw response.errors[0];
        }
        return response.data;
    }
}

const cachedClients: Record<string, DesGraphQLClient> = {};
const allClientsEventEmitter = new EventEmitter();
export function createGraphqlClient(host: string) {
    if (!cachedClients[host]) {
        cachedClients[host] = new DesGraphQLClient({
            host,
            Auth: authClientSingleton,
            cors: !!host
        });
        cachedClients[host].on('versionMismatch', () => {
            allClientsEventEmitter.emit('versionMismatch');
        });
    }
    return cachedClients[host];
}

export function onGraphqlVersionError(cb: () => any) {
    allClientsEventEmitter.on('versionMismatch', () => {
        cb();
    });
    if (_.some(cachedClients, c => c.hasDetectedMismatch)) {
        cb();
    }
}
